<template>
  <a-modal
    title="添加/编辑任务"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="saveMissionApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="任务名称"
        :validateStatus="checkParams.titleStatus"
        :help="checkParams.titleStatusMsg"
      >
        <a-input
          placeholder="请输入任务名称"
          v-decorator="['title', {initialValue: mission.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上级任务"
        v-if="mission.parentKeyId > 0"
      >
        {{ mission.parentTitle }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-textarea :rows="5" v-model="mission.desc" placeholder="备注" />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="计划时间"
      >
        <a-range-picker
          style="width: 60%"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          v-model="mission.planTime"
          @change="changeTime"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="优先级"
        :validateStatus="checkParams.priorityLevelStatus"
        :help="checkParams.priorityLevelStatusMsg"
      >
        <a-select
          style="width:170px"
          v-model="mission.priorityLevel"
          placeholder="请选择优先级"
          @change="changeLevel"
        >
          <a-select-option :key="0" :value="0">请选择优先级</a-select-option>
          <a-select-option v-for="item in priorityLevel" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { saveMission } from '@/api/mission'

export default {
  name: 'MissionInfo',
  components: {
  },
  props: {
    priorityLevel: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null,
        priorityLevelStatus: 'success',
        priorityLevelStatusMsg: null
      },
      mission: {},
      confirmLoading: false,
      tabindex: 1
    }
  },
  created () {
  },
  methods: {
    ininData (data, tabindex = 1) {
      this.mission = data
      this.checkParams.titleStatus = 'success'
      this.checkParams.titleStatusMsg = null
      this.checkParams.priorityLevelStatus = 'success'
      this.checkParams.priorityLevelStatusMsg = null
      this.tabindex = tabindex
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    saveMissionApi () {
      const _this = this
      if (_this.mission.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入任务名称'
        return false
      }
      if (_this.mission.priorityLevel !== 0) {
        this.checkParams.priorityLevelStatus = 'success'
        this.checkParams.priorityLevelStatusMsg = null
      } else {
        this.checkParams.priorityLevelStatus = 'error'
        this.checkParams.priorityLevelStatusMsg = '请选择优先级'
        return false
      }
      _this.confirmLoading = true
      saveMission(_this.mission).then((res) => {
        if (res.errorCode === 0) {
          if (_this.mission.keyId > 0) {
            _this.$message.success('更新任务成功')
          } else {
            _this.$message.success('添加任务成功')
          }
          _this.confirmLoading = false
          _this.visible = false
          // _this.$refs.table.refresh()
          this.$emit('ok', this.tabindex)
        } else {
          _this.confirmLoading = false
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    },
    titleCheck (rule, value, callback) {
      const _this = this
      _this.mission.title = value.trim()
      if (_this.mission.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入任务名称'
        callback(new Error('请输入任务名称'))
      }
    },
    changeLevel (e) {
      if (e !== 0) {
        this.checkParams.priorityLevelStatus = 'success'
        this.checkParams.priorityLevelStatusMsg = null
      } else {
        this.checkParams.priorityLevelStatus = 'error'
        this.checkParams.priorityLevelStatusMsg = '请选择优先级'
      }
    },
    changeTime (e) {
      this.mission.planTime = e
      console.log('changeTime', e)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
